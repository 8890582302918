<template>
  <div class="header">
    <div class="logo">
      <a href="/">
        <img height="100%" src="/images/logo.svg">
      </a>
    </div>
    <div class="right-menu">
      <div class="right-menu-icon" v-if="isLogin">
        <a href="/mypage">
          <img src="/images/mypage-icon.svg">
          <div class="icon-label">マイページ</div>
        </a>
      </div>
      <div class="right-menu-icon" v-else>
        <a href="/login">
          <img src="/images/login-icon.svg">
          <div class="icon-label">ログイン</div>
        </a>
      </div>
      <div class="right-menu-icon" @click="isOpenHeaderModal = true">
        <img src="/images/menu-icon.svg">
        <div class="icon-label">メニュー</div>
      </div>
    </div>
    <div class="header-modal" :class="{'active': isOpenHeaderModal}">
      <div class="header-modal-close">
        <div class="header-modal-close-button" @click="isOpenHeaderModal = false">
          <img src="/images/close-icon.svg">
          <div class="header-modal-close-button-label">閉じる</div>
        </div>
      </div>
      <div class="header-modal-content">
        <div class="header-modal-search">
          <facilities-free-word-search-form></facilities-free-word-search-form>
        </div>
        <div class="header-modal-menu">
          <div class="header-modal-menu-item">
            <a href="/">条件を指定してスポーツ施設を探す</a>
            <hr>
          </div>
          <div class="header-modal-menu-item">
            <a href="/articles" class="header-modal-menu-link">
              <div class="header-modal-menu-title">
                Articles
              </div>
              <div class="header-modal-menu-sub">
                ― 記事を読む ―
              </div>
            </a>
            <hr>
          </div>
          <div class="header-modal-menu-item">
            <a href="/notifications" class="header-modal-menu-link">
              <div class="header-modal-menu-title">
                News
              </div>
              <div class="header-modal-menu-sub">
                ― 更新情報 ―
              </div>
            </a>
            <hr>
          </div>
          <div class="header-modal-menu-item">
            <div>お問い合わせ</div>
            <div class="header-modal-contacts">
              <a class="header-modal-menu-link" href="/apply_delete_facility">
                <img src="/images/arrow-right-icon.svg">
                <div>施設の閉業・休業に関するご報告</div>
              </a>
              <a class="header-modal-menu-link" href="/contact">
                <img src="/images/arrow-right-icon.svg">
                <div>スポレボに関するご意見・ご要望</div>
              </a>
            </div>
            <hr>
          </div>
          <div class="header-modal-menu-item" v-if="isAdmin">
            <div>● 管理者メニュー</div>
            <div class="header-modal-contacts">
              <a class="header-modal-menu-link" href="/users">
                <img src="/images/arrow-right-icon.svg">
                <div>管理者用｜ユーザー｜一覧</div>
              </a>
              <a class="header-modal-menu-link" href="/admin_notifications">
                <img src="/images/arrow-right-icon.svg">
                <div>管理者用｜更新情報｜一覧</div>
              </a>
              <a class="header-modal-menu-link" href="/admin_articles">
                <img src="/images/arrow-right-icon.svg">
                <div>管理者用｜記事｜一覧</div>
              </a>
              <a class="header-modal-menu-link" href="/admin_facilities">
                <img src="/images/arrow-right-icon.svg">
                <div>管理者用｜投稿施設｜一覧</div>
              </a>
              <a class="header-modal-menu-link" href="/facility_types">
                <img src="/images/arrow-right-icon.svg">
                <div>管理者用｜施設カテゴリ｜一覧</div>
              </a>
              <a class="header-modal-menu-link" href="/equipment_types">
                <img src="/images/arrow-right-icon.svg">
                <div>管理者用｜設備タグ｜一覧</div>
              </a>
              <a class="header-modal-menu-link" href="/sports_types">
                <img src="/images/arrow-right-icon.svg">
                <div>管理者用｜スポーツタグ｜一覧</div>
              </a>
            </div>
            <hr>
          </div>
        </div>
        <div class="header-modal-bottom-button">
          <div v-if="!isLogin" class="header-modal-button-in-guest">
            <a href="/login" class="header-modal-filled-button">ログイン</a>
          </div>
          <div v-else class="header-modal-button-in-login">
            <a class="header-modal-filled-button" href="/mypage">マイページ</a>
            <a class="header-modal-outline-button" data-method="delete" href="/logout">ログアウト</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../packs/facilities_free_word_search_form'
export default {
  data() {
    return {
      isSearchFacilityButtonActive: false,
      opacity: 1,
      timeoutId: '',
      scrollY: 0,
      isOpenHeaderModal: false,
      searchWord: "",
    }
  },
  props: {
    userType: {
      type: String,
      default: ""
    },
  },
  components: {
  },
  methods: {
    handleScroll() {
      this.scrollY = location.pathname == "/" ? window.scrollY : 120;
      const width = window.innerWidth;
      clearTimeout(this.timeoutId);
      this.opacity = location.pathname == "/" ? 0 : 1;
      if(width < 992 && this.scrollY < 10) {
        this.opacity = 0;
        return;
      }
      this.timeoutId = setTimeout(() => {
        this.opacity = 1;
      }, 300);
    },
  },
  created(){
    const width = window.innerWidth;
    window.addEventListener("scroll", this.handleScroll);
    if(width < 992 && this.scrollY < 10) {
      this.opacity = 0;
    }
  },
  computed: {
    searchFacilityButtonClass(){
      if(this.isSearchFacilityButtonActive) return "nav-item active";
      return "nav-item"
    },
    isAdmin(){
      return this.userType == "admin";
    },
    isGuest(){
      return this.userType == "guest";
    },
    isFacility(){
      return this.userType == "facility";
    },
    isNormal(){
      return this.userType == "normal";
    },
    isArticle(){
      return this.userType == "article";
    },
    isLogin(){
      return this.isAdmin || this.isFacility || this.isNormal || this.isArticle;
    }
  },
  watch: {

  }
}
</script>
