import { LitElement, html, css } from 'lit';

class FacilitiesFreeWordSearchForm extends LitElement {
  static properties = {
    text: { type: String, reflect: true },
    isShadow: { type: Boolean, reflect: true }
  }
  static styles = css`
    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
    .header-modal-search-input {
      position: relative;
      max-width: 540px;
      margin: 0 auto 56px;
    }
    .header-modal-search-input input {
      -webkit-appearance: none;
      line-height: 150%;
      border: none;
      outline: none;
      width: 100%;
      border-radius: 40px;
      padding: 14px 35px 14px 16px;
      border: none;
      font-size: 14px;
      background-color: white;
      &::placeholder {
        color: #A8ABA6;
      }
    }
    .header-modal-search-input .search-icon {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  `
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.isShadow = false;
    this.text = '';
  }
  search(event) {
    if(this.searchWord === "" || event?.keyCode === 229) return;
    const params = new URLSearchParams({
      page: 1,
      q: JSON.stringify({
        free_word: this.text,
      })
    }).toString()
    const url = `/facilities?${params}`
    location.href = url;
  }
  handleInput(e) {
    this.text = e.target.value;
  }
  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.search(e);
    }
  }
  render() {
    return html`
      <div class="header-modal-search-input">
        <input type="search" placeholder="スポーツ施設を探そう！" .value="${this.text}" style="${this.isShadow ? 'box-shadow: 0px 4px 0px 0px rgba(38, 50, 56, 0.16);' : ''}" @input="${this.handleInput}" @keydown="${this.handleKeyDown}">
        <div class="search-icon" @click="${this.search}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clip-path="url(#clip0_3853_18712)">
              <path d="M15.0193 16.5L9.17404 10.6548C8.70871 11.039 8.17359 11.3396 7.56867 11.5568C6.96374 11.7739 6.33794 11.8825 5.69126 11.8825C4.10062 11.8825 2.75442 11.3318 1.65265 10.2303C0.550884 9.1288 0 7.78295 0 6.19273C0 4.60249 0.550744 3.25614 1.65223 2.15368C2.75372 1.05122 4.09958 0.5 5.68982 0.5C7.28004 0.5 8.62638 1.05088 9.72885 2.15265C10.8313 3.25442 11.3825 4.60062 11.3825 6.19126C11.3825 6.85584 11.271 7.49059 11.0479 8.09552C10.8247 8.70044 10.527 9.22661 10.1548 9.67404L16 15.5193L15.0193 16.5ZM5.69126 10.4866C6.89038 10.4866 7.90604 10.0705 8.73826 9.23826C9.57048 8.40604 9.98659 7.39038 9.98659 6.19126C9.98659 4.99215 9.57048 3.97648 8.73826 3.14426C7.90604 2.31204 6.89038 1.89593 5.69126 1.89593C4.49215 1.89593 3.47648 2.31204 2.64426 3.14426C1.81206 3.97648 1.39595 4.99215 1.39595 6.19126C1.39595 7.39038 1.81206 8.40604 2.64426 9.23826C3.47648 10.0705 4.49215 10.4866 5.69126 10.4866Z" fill="#A8ABA6"/>
            </g>
            <defs>
              <clipPath id="clip0_3853_18712">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    `;
  }
}
if (!customElements.get('facilities-free-word-search-form'))
  customElements.define('facilities-free-word-search-form', FacilitiesFreeWordSearchForm);